.injuries-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  background-color: #f1efef; /* Light grey background */
}

.welcome-message {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: x-large;
  margin-left: 16px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 16px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff; /* White background */
  border-radius: 50px; /* Rounded corners */
  padding: 10px 15px;
}

.search-icon {
  margin-right: 10px;
  color: #ababab; /* Dark grey icon */
}

.search-input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  color: #333333; /* Dark grey text */
}

.search-input::placeholder {
  color: #aaaaaa; /* Light grey placeholder text */
}


.injuries-list-container {
  flex: 1;
  overflow-y: visible;
  margin-top: 8px;
}

.injuries-list-label {
  margin-left: 16px;
  margin-bottom: 8px;
  font-size: 0.9rem;
  font-weight: 300;
}

.injuries-list {
  padding-left: 16px;
  padding-right: 16px;
}

.injury-card {
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 90%;
  text-align: left;
}

.injury-image {
  object-fit: cover;
}

.fab-container {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 100;
}
