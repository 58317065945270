.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  z-index: 100;
  background-color: #f1efef; /* Light grey background */
}

.app-bar-action {
  margin-top: auto;
  margin-bottom: auto;
}

.app-bar-right {
  display: flex;
  align-items: center;
}

.app-bar-left {
  display: flex;
  align-items: center;
}
