.add-injury-container {
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  background-color: white;
}

.add-injury-container-header {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;  
  padding: 16px;
  max-height: 20px;
  min-height: 20px;  
}

.add-injury-container-header-title {
  font-size: 18px;
  font-weight: bold;
  margin-left: 16px;
  text-align: left;
}

.add-form {
  padding: 16px;
  height: 100%;
  overflow: scroll;
}

.image-preview-container {
  display: flex;
  overflow-x: scroll;
  margin-top: 16px;
  margin-bottom: 16px;
}

.image-preview {
  position: relative;
  margin-right: 16px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.image-remove-button {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
}

.success-message {
  font-size: large;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.success-message-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loading-message-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.success-message-image img {
  border-radius: 50%;
  width: 100%;
  margin-bottom: 16px;
}

.loading-message-image img {
  border-radius: 50%;
  width: 80%;  
  margin-bottom: 16px;
}

.loading-screen {
  font-size: large;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}