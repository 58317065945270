.main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.app-content {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
}

.carousel-wrapper {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.carousel__container {
  display: flex;
  height: 100%;
  width: 100%;
}

.carousel__slide {
  flex: 0 0 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel__slide__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.slide-content {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
