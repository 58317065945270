.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.sub-page {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: -webkit-fill-available;
  z-index: 999;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 24px;
}
