.injury-view-container {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: inherit;
    background-color: white;
}

.container-header {
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-height: 20px;
    min-height: 20px;
}

.container-header-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

}

.container-content {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    overflow-y: scroll;    
    height: 100%;
}

.container-content-top {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: visible;
}

.container-content-bottom {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 30vh;
}

.injury-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    overflow: hidden;
}

.injury-title {
    font-size: x-large;
    margin-bottom: 8px;
}

.injury-subline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
}

.injury-description {
    font-size: 16px;
    margin-bottom: 16px;
    max-height: 20vh;
    overflow-y: scroll;
    overflow: visible;
}

.injury-timestamp {
    font-size: 12px;
    color: #666;
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    flex-direction: row;
}

.edit-timestamp {    
    font-size: 14px;
    color: #666;
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 8px;
}

.injury-location {
    font-size: 16px;
    margin-bottom: auto;
    margin-top: auto;
}

.image-preview-container {
    display: flex;
    overflow-x: scroll;    
    margin-bottom: 16px;    
    min-height: 120px;
}

.image-preview {
    position: relative;
    margin-right: 16px;
}

.image-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.comments-list {
    margin-bottom: 8px;
    overflow: visible;
}

.comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 8px 8px;
    border-radius: 8px;
}

.comment .comment-user-container {
    display: flex;
    flex-direction: row;
    justify-content: top;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 2px;
}

.comment .comment-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: wrap;
}

.timestamp {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    font-size: 12px;
    color: #666;
    font-weight: normal;
}

.add-comment {
    display: flex;
    flex-direction: row;    
    justify-content: top;
}

.label {
    font-weight: lighter;
    margin-right: 8px;
    font-size: small;
    color: #666;
    margin-bottom: 8px;
}

/* Modal/Overlay for Enlarged Image */
.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's on top of everything */
    cursor: pointer;
  }
  
  .image-modal {
    max-width: 90%;
    max-height: 90%;
  }
  
  .image-modal img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }