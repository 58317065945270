.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 1rem; /* Add padding to handle overflow */
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
  }
  
  .login-logo {
    width: 35%;
    margin-bottom: 1rem;
  }

  .app-title {
    text-align: center;
    margin-bottom: 1rem;
  }

  .login-box {
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 400px; /* Set a max-width to prevent overflow */
    box-sizing: border-box; /* Ensure padding is included in the element's total width */
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .pingu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .pingu {
    height: 120px;
  }